<template>
  <b-container fluid class="pt-5">
    <b-alert
      class="text-center"
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      {{ isError }}
    </b-alert>
    <b-row class="justify-content-md-center align-items-center">
      <b-col
        lg="5"
        md="5"
        sm="10"
        xs="10"
        class="left-text"
        data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
      >
        <h2 class="text-center example-heading">
          <strong>Please validate your self</strong>
        </h2>
        <b-row class="justify-content-md-center align-items-center">
          <recaptcha
            @error="onError"
            @success="onSuccess"
            @expired="onExpired"
          />
        </b-row>
      </b-col>
    </b-row>
    <b-row class="dnmd content-end">
      <b-row align-h="between">
        <b-col cols="4">
          <b-img
            src="../static/images/homeStatistics.png"
            class="rounded img-fluid d-block mx-auto"
            alt="First Vector Graphic"
          />
        </b-col>
        <b-col cols="4">
          <b-img
            src="../static/images/homeRocket.png"
            class="rounded img-fluid d-block mx-auto"
            alt="First Vector Graphic"
          />
        </b-col>
      </b-row>
    </b-row>

    <loading
      :is-loading="isLoading"
      :variants="variants"
      :hostname="website.hostname"
    />
  </b-container>
</template>

<script>
import '@/static/css/app.css'
import Loading from '@/components/loading'
export default {
  layout: 'seo',
  name: 'WebAuditor',
  components: {
    Loading
  },
  data() {
    return {
      variants: [
        'primary',
        'secondary',
        'danger',
        'warning',
        'success',
        'info',
        'light',
        'dark'
      ],
      website: {
        hostname: this.$route.query.hostname
      },
      showDismissibleAlert: false,
      errorMsg: null
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading
    },
    isError() {
      return this.$store.getters.getErrorMsg
    }
  },

  mounted() {
    this.$nextTick(function() {
      console.log('Add website mounted ' + Date())
    })
  },
  methods: {
    onSuccess(token) {
      if (token) {
        this.addWebsite()
      }
      this.$store.commit('setErrorMsg', 'validation fail please try again!')
      if (this.$store.state.errMsg) {
        this.showDismissibleAlert = true
      }
    },
    onExpired() {
      this.$router.push('/verify')
    },
    onError(error) {
      this.$store.commit('setErrorMsg', error)
      if (this.$store.state.errMsg) {
        this.showDismissibleAlert = true
      }
    },
    async addWebsite() {
      await this.$store.dispatch('addWebsite', this.website)
      if (this.$store.state.errMsg) {
        this.$router.push('/')
      }
      if (this.$store.state.loading) {
        await this.$store.dispatch('checkSiteStatus', this.website)
        this.$store.commit('setLoading', false)
        if (this.$store.state.errMsg) {
          this.$router.push('/')
        }
      }
    }
  },
  head() {
    return {
      title: 'WebAuditor'
    }
  }
}
</script>

<style>
#input-1 {
  background-color: #fff;
  border: solid #fff;
  text-transform: lowercase;
}
html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
}
p {
  margin-top: 3rem;
  margin-bottom: 0rem;
}
#addSite {
  color: #212529;
  background-color: #fab414;
  border-color: #fab414;
}
#addSite:hover {
  color: #fff;
}
.input-group {
  border: solid #343a40 2px;
  border-radius: 20px;
  overflow: hidden;
}
.hostname-input input {
  text-transform: lowercase;
}
.example-bg-section {
  background-color: #f9f9f9 !important;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.example-heading {
  padding-bottom: 3rem;
}
a.card-style {
  color: black;
  text-decoration: none;
}
.card-style-hover {
  border: 2px solid transparent;
}
.card-style-hover:hover {
  border: 2px solid #fab414;
}
.card-col {
  padding-bottom: 10px;
}
.card-style-custom:hover {
  border: 2px solid #fab414;
}
.card-style-custom {
  border: 2px solid transparent;
}
.card-title {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
  color: #343a40;
}
.b-avatar {
  width: 30px;
  height: 30px;
  border-radius: 2px !important;
  border: 1px solid transparent;
  background-color: transparent;
}
.card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.25rem;
}
.content-end {
  margin-top: 200px;
}
@media only screen and (max-width: 999px) {
  .showcase-container {
    grid-template-columns: 100%;
  }
  .example-bg-section {
    padding-bottom: 2rem;
  }
}
@media only screen and (max-width: 576px) {
  .b-avatar {
    width: 24px;
    height: 24px;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .card-body {
    padding: 1rem 0.75rem;
    border: 2px solid transparent;
  }
  #showcase-sitescore {
    transform: translate(-90%, -73%) scale(0.4);
  }
  .example-bg-section {
    padding-bottom: 1rem;
  }
}
</style>
